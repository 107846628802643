import Vue from 'vue'

import '@fortawesome/fontawesome-free/css/all.css'

/*Font Awesome*/
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas,faHome, faFemale, faMale, faCalendarAlt, faClipboardList, faBook, faMoneyBillAlt, faBuilding, faUserTie, faEdit, faEye, faTrash, faSearch,faQuestionCircle, faBorderAll, faLanguage} from '@fortawesome/free-solid-svg-icons'



Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas,faHome,faFemale,faMale,faCalendarAlt,faClipboardList,faBook,faMoneyBillAlt,faBuilding,faUserTie,faEdit,faEye,faTrash,faSearch,faQuestionCircle, faBorderAll,faLanguage) // Include needed icons
